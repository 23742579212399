import { useEffect, useState } from 'react'
import { Provider as AppBridgeProvider, useAppBridge } from '@shopify/app-bridge-react'
import createApp from '@shopify/app-bridge'
import { useLocation, BrowserRouter, Route, Routes } from 'react-router-dom'
import { AppProvider as PolarisAppProvider } from '@shopify/polaris'
import { PolarisVizProvider } from '@shopify/polaris-viz'
import { Redirect } from '@shopify/app-bridge/actions'
import { getSessionToken } from '@shopify/app-bridge-utils'
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js'
import formbricks from '@formbricks/js/app'

import MonitorDetail from './scenes/monitor-detail'
import Index from './scenes/index/index'
import Settings from './scenes/settings'
import { httpRequest } from './lib/request'
import { appRedirect } from './lib/redirect'
import { BASE_URL } from './lib/constants/env'

import '@shopify/polaris/build/esm/styles.css'
import '@shopify/polaris-viz/build/esm/styles.css'

const posthogOptions = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
}
const formbrickOptions = {
    environmentId: 'clxczwtm1045yp1yxd2a7q9zg',
    apiHost: 'https://app.formbricks.com'
}

function initThirdPartyApps(host: string) {
    posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY!, {
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
    })

    if (typeof window !== 'undefined') {
        formbricks.init({
            ...formbrickOptions,
            userId: host
        })
    }
}

async function checkSession(token: string): Promise<{ expired: boolean; host?: string }> {
    return await httpRequest<{ expired: boolean; host?: string }>({
        method: 'GET',
        url: BASE_URL + '/api/auth/session',
        headers: {
            Authorization: token
        }
    })
}

function ShopCheckApp() {
    const [loading, setLoading] = useState<boolean>(true)
    const app = useAppBridge()
    const location = useLocation()

    useEffect(() => {
        getSessionToken(app).then((token) => {
            checkSession(token).then(({ expired, host }) => {
                if (expired) {
                    appRedirect(app, '/?reauth=1')
                } else {
                    initThirdPartyApps(host!)
                    setLoading(false)
                }
            })
        })
    }, [])

    return loading ? (
        <div></div>
    ) : (
        <PolarisAppProvider
            i18n={
                {
                    /* this is handled in the index.tsx with i18n provider */
                }
            }
        >
            <PolarisVizProvider>
                <Routes location={location}>
                    <Route path='/' element={<Index />} />
                    <Route path='/details/:checkId' element={<MonitorDetail />} />
                    <Route path='/settings' element={<Settings />} />
                </Routes>
            </PolarisVizProvider>
        </PolarisAppProvider>
    )
}

function AppBridgeWrapper() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const shop = params.get('shop')
    const host = params.get('host')
    const session = params.get('session')
    const embedded = params.get('embedded') === '1'
    const shouldReAuth = params.get('reauth') === '1'
    const scopes = 'read_products,read_content,read_themes,write_themes,read_price_rules,read_inventory,read_orders,read_locations'
    const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY as string
    const redirectUri = process.env.REACT_APP_PUBLIC_HOST + '/api/auth'
    const oauthUrl = `https://${shop}/admin/oauth/authorize?client_id=${apiKey}&scope=${scopes}&redirect_uri=${redirectUri}`

    if (shop && window.top === window.self) {
        window.location.assign(oauthUrl)

        return null
    } else if (shouldReAuth || (embedded && !session)) {
        const app = createApp({
            apiKey: apiKey,
            host: host as string,
            forceRedirect: true
        })

        Redirect.create(app).dispatch(Redirect.Action.REMOTE, oauthUrl)

        return null
    }

    /**
     * https://www.justblackmagic.com/2022/04/27/shopify-embedded-app-installation-and-auth/
     */

    return (
        <AppBridgeProvider
            config={{
                forceRedirect: true,
                apiKey: apiKey,
                host: new URLSearchParams(location.search).get('host')!
            }}
        >
            <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={posthogOptions}>
                <ShopCheckApp />
            </PostHogProvider>
        </AppBridgeProvider>
    )
}

function App() {
    return (
        <BrowserRouter>
            <AppBridgeWrapper />
        </BrowserRouter>
    )
}

export default App
